:root {
  --main-font: "Space Mono", monospace;
  --title-text-size: 18px;
  --main-text-size: 1rem;
  --main-text-size-tablet: 0.95rem;
  --main-text-size-mobile: 16px;
  --project-title-text-size: 22px;
  --main-text-color: black;
  --header-sub-title: red;
  --divider: black;
  --divider-width: 2px;
  --section-title-size: 55px;
}

* {
  font-family: var(--main-font);
  line-height: 3vh;
  /* padding: 0px; */
  /* margin: 0; */
  /* width: 100%; */
}

html {
}

body {
  overflow-x: hidden;
  margin: 0px;
  width: 100%;
}
iframe {
  width: 55vw;
  height: 100vh;
}

/* @keyframes waveringBlur {
  from {
    -webkit-backdrop-filter: blur(1.5px);
    backdrop-filter: blur(1.9px);
  }
  to {
    -webkit-backdrop-filter: blur(3.5px);
    backdrop-filter: blur(4.3px);
  }
} */

/* canvas.webGL {
  position: fixed;
  outline: none;
  top: 0;
  left: 0;
} */

a {
  color: blue;
  font-weight: bold;
}

a:hover {
}

ul {
  height: 80%;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0.3em;
}

header {
  transform: scale3d(1.5, 2.7, 1.5) rotate3d(15, 11, -10, 45deg);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 6vh;
  max-width: 100%;
  top: 0;
  position: absolute;
  background-color: transparent;
  z-index: 2;
  color: black;
}

.mainContainer {
  overflow: hidden;
  gap: 0.7em;
  max-height: -moz-fit-content;
  max-height: fit-content;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  background: white;
  animation: waveringBlur 2.5s infinite alternate;
}

.header--title {
  font-weight: 100;
  color: red;
}

.header--subtitle {
  color: var(--header-sub-title);
  font-weight: 200;
}

.header--buttons > h4 {
  color: black;
  animation: glow alternate infinite 2s;
}

.marquee__container {
  display: flex;
  margin: auto;
  overflow: hidden;
}
.marquee__text {
  white-space: nowrap;
  will-change: transform;
  animation: marquee-left 55s linear infinite;
}
@keyframes marquee-left {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(-69.63%, 0, 0);
  }
}

.me {
  width: 25%;
  align-self: flex-end;
}

.marquee__container--footer {
  display: flex;
  margin: auto;
  overflow: hidden;
}
.marquee__text--footer {
  white-space: nowrap;
  will-change: transform;
  animation: marquee-right 40s linear infinite;
}
@keyframes marquee-right {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(-55.95%, 0, 0);
  }
}

/* ------------------------------------------------------------ */

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: left;
  font-size: var(--main-text-size);
  color: var(--main-text-color);
  box-sizing: border-box;
  width: 60%;
  max-height: -moz-fit-content;
  max-height: fit-content;
  padding: 2vh;
  row-gap: 2em;
  background-color: #fffffff2;
}
@media screen and (max-width: 600px) {
  section {
    width: 85%;
    row-gap: 0;
  }
  section.projects {
  }
}

section:nth-child(even) {
  align-self: center;
  text-align: center;
}
section > p {
  text-align: justify;
  align-self: self-end;
  background-color: #ffffff9e;
  color: black;
}

/* about & experience */
section.about.container-fluid {
  width: 85%;
  z-index: 2;
  gap: 0;
  background-color: transparent;
}
section.about.container-fluid > a > span {
  font-size: var(--main-text-size);
}

section.about.container-fluid > p {
  background-color: white;
}

section.experience.container-fluid {
  z-index: 2;
}

.professionalCont {
  font-size: 0.85rem;
}
.professionalCont > a.contributionLink {
  text-align: left;
  display: flex;
  flex-direction: row;
  margin: auto;
}

span.about--desc {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 75%;
  margin: auto;
  text-align: justify;
}

span.about--desc > p {
  width: 50%;
  align-self: flex-end;
}

/* projects */
section.projects {
  width: 80%;
  row-gap: 1.5em;
  border-bottom: none;
  margin: auto;
  z-index: 2;
}

.projects--description > p {
  background-color: #ffffff9e;
  line-height: 2vh;
  /* max-width: 200px; */
}

@media screen and (max-width: 999px) {
  .projects--description > p {
    font-size: var(--main-text-size-tablet);
  }
}

@media screen and (max-width: 768px) {
  section > p {
    align-self: center;
  }
  .marquee__text > span {
    font-size: 1rem;
    /* margin-top: 1em; */
    line-height: 3rem;
  }
  /* about */
  .me {
    width: 35%;
    align-self: center;
  }
  section.about {
    width: 80%;
  }
  span.about--desc > p {
    align-self: center;
    width: 100%;
  }

  /* experience */
  section.experience {
  }
  .projects--list-item {
    justify-content: center !important;
  }
  .projects--list {
    /* padding: 5.5vw; */
  }
  .projects--list-item {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  * {
    line-height: 3vh;
  }
  /* experience */
  section > h3.title {
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.25rem;
  }
  h1.header--title {
    font-size: 30px;
  }
  h2.header--subtitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 337px) {
  * {
    line-height: 1.5vh;
  }
  /* experience */
  section > h3.title {
    font-size: 50px;
  }
}
@media screen and (min-width: 769px) {
  section {
    /* problematic  8-14-23 */
    /* right: -5%; */
  }
  section.about {
    /* problematic  8-14-23 */
    right: -10%;
  }
  section.experience {
    /* problematic  8-14-23 */
    right: -10%;
  }
  section:last-child {
    /* problematic  8-14-23 */
    right: -5%;
  }
}

h3.title {
  font-size: var(--section-title-size);
  font-weight: 200;
  text-align: left;
  color: black;
  text-transform: capitalize;
  margin: 0;
}

p {
  font-size: var(--main-text-size);
  /* font-size: 1rem; */
}

@media screen and (max-width: 925px) {
  p {
    font-size: var(--main-text-size-tablet);
  }
}

@media screen and (max-width: 481px) {
  p {
    font-size: var(--main-text-size-mobile);
  }
}

h3.projects--title {
  text-align: right;
  font-size: var(--project-title-text-size);
  margin: 0;
  text-decoration: underline;
}

.projects--description {
  width: 100%;

  margin: 0px;
  padding: 0px;
}

.projects--item-desc {
  text-align: justify;
}
p.projects--item-desc > span {
  font-weight: 900;
  font-style: italic;
}

.projects--list {
  width: 100%;
  align-self: center;
  grid-template-columns: repeat(3, 1fr);
  -ms-grid-columns: repeat(3, 1fr);
}

@media screen and (min-width: 1500px) {
  .projects--list {
    display: flex;
    flex-direction: row;
  }
  .projects--list-item {
    justify-content: center !important;
  }
}

.projects--list-item {
  display: flex;
  justify-content: left;
  width: 100%;
}
.projects--list-item:nth-child(even) {
  justify-content: right;
}

.projects--list-item > a > span > img {
  aspect-ratio: 1/5;
  object-fit: contain;
  width: 100%;
  max-width: 53%;
  max-height: 13vw;
  float: left;
  margin: 1px;
}
@media screen and (max-width: 758px) {
  h3.projects--title {
    text-align: center;
  }
  .projects--description {
    display: flex;
    flex-direction: column;
  }
  .projects--list-item > a > span > img {
    float: none;
    margin: auto;
    max-height: 20vw;
    max-width: 63%;
  }
}

.projects--list-item > a {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1510px) {
  .projects--list-item > img {
    height: 26vh;
  }
}

.projects--item-link {
  text-align: center;
  width: 62%;
  display: flex;
  flex-direction: row-reverse;
  margin: auto;
}

.skills {
  bottom: 10%;
}

.resume {
  bottom: 0%;
  padding: 2vh;
  position: fixed;
}

summary {
  width: -moz-fit-content;
  width: fit-content;
}

footer {
  transform: scale3d(1.5, 3.3, 1.5) rotate(19deg) translate3d(12px, -232%, 12px);
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 1vw;
  position: absolute;
  bottom: 0;
  z-index: 1;
  color: white;
  mix-blend-mode: multiply;
  background-color: black;
}
@media screen and (max-width: 768px) {
  header,
  footer {
    transform: none;
    color: black;
  }
  header {
    top: 0;
    position: sticky;
    background-color: white;
    z-index: 3;
  }
  footer {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 1vw;
    position: sticky;
    bottom: 0;
    background-color: white;
  }
}

/* Pause animation when reduced-motion is set */
@media (prefers-reduced-motion: reduce) {
  .marquee__text {
    animation-play-state: paused !important;
  }
}
